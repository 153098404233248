@use './config/' as *;
@forward "./main-scss/";
.waf-squad {
    --_content-height: 4rem;
    --_role-height: 2.2rem;
    --_icon-size: 1.5rem;
    .waf-head .title {
        @extend %neutral-50;
    }
}
.layout-wrapper {
    @extend %relative;
}
.squad {
    &-head {
        @extend %mb-8;
        @extend %relative;
        @extend %flex-c-c;
        &::after {
            content: "";
            height: .1rem;
            z-index: var(--z-negative);
            @extend %absolute;
            @extend %neutral-100-bg-3;
            @extend %w-100;
        }
    }
    &-title {
        padding: 0.2rem var(--space-2);
        border-radius: 0.3rem;
        @extend %secondary-1000;
        @extend %font-12;
        @extend %secondary-600-bg;
        @extend %uppercase;
    }
    &-wrapper {
        @include grid(2, var(--space-3));
    }
    &-item {
        @extend %w-100;
        @extend %relative;
        @each $icon, $code in $squad {
            &.#{$icon} .player-role::before {
                @include icon($icon, 14)
            }
        }
        &.captain {
            &::after {
                content: "C";
                position: absolute;
                top: 1rem;
                right: 1rem;
                width: var(--_icon-size);
                height: var(--_icon-size);
                @extend %font-12;
                @extend %flex-c-c;
                @extend %circle-radius;
                @extend %neutral-50;
                @extend %neutral-1000-bg;
            }
        }
        &.overseas {
            &::after {
                position: absolute;
                top: 1rem;
                right: 1rem;
                width: var(--_icon-size);
                height: var(--_icon-size);
                rotate: -45deg;
                @include icon(flight, 8);
                @extend %flex-c-c;
                @extend %circle-radius;
                @extend %neutral-50;
                @extend %neutral-1000-bg;
            }
        }
    }
    &-group {
        @extend %py-4;
        &:first-child {
            @extend %pt-0;
            .squad-head {
                @extend %my-4;
            }
        }
    }
}
.player {
    &-thumbnail {
        aspect-ratio: 2/3;
        width: calc(100% - 5rem);
        margin: auto;
        @extend %relative;
        .player-image {
            bottom: 0;
            @extend %absolute;
        }
    }
    &-country {
        @extend %d-none;
    }
    &-wrap {
        border: .1rem solid clr(neutral-100);
        @include background(clr(secondary-50), "patterns/player-card-bg.jpg", top/contain no-repeat);
        @extend %half-radius;
        @extend %hidden;
        @extend %relative;
        .view-more {
            position: absolute;
            inset: 0;
            background-color: transparent;
            @extend %font-0;
        }
        .toggle-stats {
            @extend %d-none;
        }
    }
    &-content {
        height: var(--_content-height);
        @extend %secondary-1000-bg;
        @extend %neutral-50;
    }
    &-role {
        position: absolute;
        bottom: var(--_content-height);
        border-top-right-radius: var(--full-radius);
        height: var(--_role-height);
        @extend %secondary-1000-bg;
        @extend %font-9-pm;
        @extend %flex-n-c;
        @extend %uppercase;
        @extend %pr-2;
        @extend %pl-1;
        &::before {
            @extend %pr-1;
        }
    }
    &-name {
        position: absolute;
        bottom: calc(var(--_content-height) + var(--_role-height) + 0.5rem);
        min-width: 15rem;
        @extend %p-1;
        @extend %secondary-1000;
        @extend %primary-500-bg;
        @extend %font-12-pr;
        @extend %uppercase;
        .name {
            font: inherit;
        }
        .last-name {
            font-weight: 700
        }
    }
    &-stats {
        &-list {
            @extend %flex;
        }
        &-item {
            flex: 1;
            flex-direction: column-reverse;
            position: relative;
            @include separator(60%, y, 0.1rem, neutral-50, 2);
            @extend %text-center;
            @extend %p-1;
            @extend %gap-1;
            @extend %flex;
            @extend %uppercase;
        }
        &-count {
            @extend %font-12-pb;
        }
        &-title {
            opacity: 0.5;
            @extend %font-9-pr;
        }
    }
}
.waf-squad {
    .tab-container-wrap-2 {
        --_content-height: 0rem;
        .squad-group:first-child .squad-title {
            @extend %secondary-1000;
        }
        .player-role {
            width: 100%;
            border-radius: 0;
            justify-content: center;
            text-align: center;
        }
    }
}
.waf-ad-page-top {
    padding-block: 2rem;
}
@include mq(col-md) {
    .waf-squad {
        --_content-height: 7rem;
        --_icon-size: 2.5rem;
        --_role-height: 3.6rem;
    }
    .squad {
        &-wrapper {
            grid-template-columns: repeat(3, 1fr);
        }
        &-item {
            &.captain::after {
                font-size: 1.3rem;
            }
            &.overseas::after {
                font-size: 1rem;
            }
        }
    }
    .player {
        &-name {
            font-size: 2rem;
            min-width: 22rem;
        }
        &-role {
            font-size: 1.2rem;
            padding-left: var(--space-2);
        }
        &-stats {
            &-title {
                font-size: 1.2rem;
            }
            &-count {
                font-size: 2rem;
            }
            &-list {
                padding-block: var(--space-2);
            }
        }
    }
}
@include mq(col-lg) {
    .squad {
        &-wrapper {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    .waf-squad {
        .player-name {
            bottom: calc(var(--_content-height) + var(--_role-height) + 2rem);
        }
    }
}
@media screen and (max-width: 350px) {
    .squad-wrapper {
        @include grid(1, var(--space-3));
    }
}