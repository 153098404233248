@use "./config/" as *;
@forward "./main-scss/";
@forward "./listing/listing-common";
@forward "squad-page";

.main-wrap {
    @extend %hide-breadcrumb;
}

.waf-search {
    padding-top: var(--header-height);
    @extend %mx-3-neg;
    &::before {
        content: "";
        top: 0;
        left: 0;
        height: var(--shape-head, 35rem);
        border-bottom: 0.4rem solid clr(primary-500);
        z-index: var(--z-patterns);
        pointer-events: none;
        @include background(clr(secondary-1000), "patterns/mobile/shape-head.png", right/cover);
        @extend %w-100;
        @extend %absolute;
    }
    .article-description {
        @extend %d-none;
    }
    .article-thumbnail{
        position: relative;
    }

    .tab-results.single {
        .head-wrap {
            .title {
                @extend %neutral-50;
            }
        }
        .news {
            background-color: unset;
            @extend %pt-0;
            @extend %pb-0;
        }
    }
    .tab-result-item {
        @extend %pb-7;
    }
    .waf-head {
        @extend %mb-4;
    }
    .waf-squad {
        margin: 0;
        .waf-head {
            padding: 0;
        }
    }
    .article-list {
        --_fontsize: 14;
        @extend %mx-3-neg;
        @extend %px-3;
        @include listing-card(vertical);
        @extend %overflow;

        .article-title {
            @extend %mb-3;
        }
        .article-item {
            @extend %article-item-scale;
        }
        .article-content {
            .item-type-icon {
                @extend %d-none;
            }
            @extend %pt-9;
            .meta-category{
                bottom: calc(100% - var(--space-7));
                @extend %absolute;

            }
        }
    }
    .videos {
        @extend %px-3;
        .article-list {
            @include card-count(1.05, var(--space-3));
            .item-type-icon {
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .img-box:after {
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            @extend %absolute;
            @extend %neutral-1000-bg-2;
        }
    }
    .news,
    .photos {
        @extend %px-3;
        .article-list {
            @include card-count(1.2, var(--space-3));
        }
        .item-type-icon {
            @extend %d-none;
        }
    }
    .news {
        .title {
            @extend %neutral-50;
        }
        @extend %secondary-1000-bg;
        @extend %pt-7;
        @extend %mb-7;
    }
    .squad {
        .title {
            @extend %neutral-50;
        }
        @extend %px-3;
        .squad-wrapper {
            @extend %px-3;
            @extend %mx-3-neg;
            @extend %overflow;
            @include card-count(2, var(--space-3));
        }
    }
    .site-search-form {
        @extend %px-3;
        @extend %pt-6;
        @extend %mb-0;
        .btn-search {
            right: var(--space-6);
        }
        .btn-text {
            @extend %font-0;
            &::before {
                content: "\e81a";
                font: 1.5rem/1.8rem "waf-font-icon";
                @extend %neutral-50;
            }
        }
    }
    .tab-section {
        border: unset;
        border-radius: unset;
        @extend %mt-0;
        .tab-list {
            border: 0.1rem solid clr(neutral-50, 2);
            @extend %half-radius;
            @extend %flex-sb-c;
            .text {
                @extend %font-10-pr;
            }
            .tab-item {
                width: 20%;
                @extend %relative;
                @extend %p-2;
                &:not(:first-child):after {
                    content: "";
                    width: 0.1rem;
                    height: 2rem;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    @extend %absolute;
                    @extend %neutral-50-bg-3;
                }
            }
            .tab-name {
                @extend %w-100;
            }
            .active {
                @extend %half-radius;
                @extend %primary-500-bg;
                .text {
                    @extend %font-10-pb;
                    @extend %secondary-1000;
                }
            }
        }
    }
    .tab-container {
        @extend %px-3;
        @extend %pt-4;
        @extend %pb-7;
    }
    .tab-active-individual {
        .squad-wrapper {
            flex-wrap: wrap;
            overflow: unset;
        }
        .article {
            &-list {
                flex-wrap: wrap;
                overflow: unset;
            }
            &-item {
                width: 100%;
            }
        }
    }
    .search-no-result {
        @extend %font-20-pb;
        @extend %secondary-500;
        @extend %capitalize;
        @extend %text-center;
        &::after,
        &::before {
            content: "---";
            @extend %mx-3;
        }
    }
}
@media screen and (min-width: $tablet-min-width) {
    .waf-search {
        padding-top: var(--space-3);
        .tab-results.single{
            .news::before{
              content: unset;
            }
        }
        &::before {
            content: "";
            height: var(--shape-head, 40rem);
            pointer-events: none;
            @include background(clr(secondary-1000), "patterns/shape-head.png", right/cover);
        }
        .layout-wrapper {
            max-width: unset;
        }
        .squad-wrapper,
        .article-list {
            flex-wrap: wrap;
        }
        .article-list {
            --_fontsize: 16;
        }
        .videos {
            .article-list {
                @include card-count(3, var(--space-3));
            }
            .img-box:after {
                background-color: clr(neutral-1000, 4);
            }
        }
        .news::before {
              content: "";
              height: 100%;
              width: 100%;
              rotate: 180deg;
              position: absolute;
              top: 0;
              left: 0;
              @include background(null, "patterns/shape-head.png", right/cover);
            }
        .news,
        .photos {
            position: relative;
            .article-list {
                @include card-count(4, var(--space-3));
            }
        }
        .squad {
            .squad-wrapper {
                @include card-count(4, var(--space-3));
            }
        }
        .news,
        .videos,
        .photos,
        .squad,
        .site-search-form,
        .tab-container {
            padding-inline: var(--container-white-space);
        }
        .site-search-form {
            padding-top: calc(var(--header-height) + 3rem);
            .btn-search {
                right: calc(var(--container-white-space) + var(--space-2));
            }
        }
        .tab-section {
            .tab-list {
                .text {
                    font-size: 1.4rem;
                }
                .active {
                    .text {
                        font-size: 1.4rem;
                    }
                }
            }
        }
        .search-no-result {
            font-size: 4.1rem;
        }
    }
}
